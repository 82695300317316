import { useState, useEffect } from "react";
import "./Login.css";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

import { login, register } from "../../store/auth/auth";
import { clearMessage } from "../../store/message/message";
import { RootState, AppDispatch, useAppDispatch } from "../../store/index";
import { setMessage } from "../../store/message/message";

const Login = (props) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);
  const { message } = useSelector((state: RootState) => state.message);

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState<any>("");
  const [pass, setPass] = useState<any>("");
  const [remember, setRemember] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const handleChangeEmail = (event: any) => {
    setEmail(event.target.value);
  };

  const handleChangePass = (event: any) => {
    setPass(event.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const body = {
      username: email,
      password: pass,
      // remember_me: remember,
    };
    dispatch(login(body))
      .unwrap()
      .then(() => {
        navigate("/");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        dispatch(setMessage(err.detail));
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  return (
    <div className="container-login d-flex flex-row">
      <div className="login-form d-flex flex-column">
        <div className="title">駐車台数監視システム</div>
        <form className="form d-flex flex-column" onSubmit={onSubmit}>
          <div className="text-field">
            <label>
              ログインID
              <input
                className="login-input"
                type="email"
                value={email}
                onChange={handleChangeEmail}
                // required
                placeholder="carparking"
                title="carparking"
                lang="en"
              />
            </label>
          </div>
          <div className="text-field">
            <label>
              パスワード
              <input
                className="login-input"
                type="password"
                // required
                value={pass}
                onChange={handleChangePass}
                lang="en"
                title="Please enter your password"
              />
            </label>
            {message !== undefined && (
              <p className="validate-warning">{message}</p>
            )}
          </div>
          <div className="checkbox d-flex flex-row">
            <input className="checkbox" type="checkbox" value="checked" />
            <div className="checkbox-text">ログイン状態を維持</div>
          </div>
          <div className="submit-field d-flex flex-row wfull jcenter">
            <input
              type="submit"
              value="ログイン"
              className="submit-button d-flex flex-column"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
export default Login;
