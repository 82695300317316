import React, { useState, useEffect, useRef } from "react";
import "./cameraDetail.css";
import { useModal } from "react-hooks-use-modal";
import Dropdown from "react-dropdown";
import cameraImage from "../../assets/imgs/test.jpg";
import { useNavigate, useLocation } from "react-router-dom";
import cameraService from "services/cameraService";
import Direction from "./Direction";
import { ToastContainer, toast } from "react-toastify";
import SelectDirection from "./SelectDirection";

const CameraDetail = (props) => {
	const { setLoading } = props;
	const [Modal, open, close, isOpen] = useModal("root", {
		preventScroll: true,
		focusTrapOptions: {
			clickOutsideDeactivates: false,
		},
	});
	const { state } = useLocation();
	const navigate = useNavigate();
	const ref = useRef<any>(null);
	const [camerasImage, setCamerasImage] = useState<any>(null);
	const [camerasIp, setCamerasIp] = useState<any>("");
	const [topDistant, setTopDistant] = useState<any>(237);
	const [leftDistant, setLeftDistant] = useState<any>(300);

	const [borderConfig, setBorderConfig] = useState<any>(null);
	const [directionTypeIn, setDirectionTypeIn] = useState<any>(1);
	const [directionTypeOut, setDirectionTypeOut] = useState<any>(1);
	const [err, setErr] = useState<any>(null);
	const [value, setValue] = useState<any>({
		on: false,
		startX: 0,
		startY: 0,
		endX: 0,
		endY: 0,
		isMouseDown: false,
		windowWidth: 0,
		windowHeight: 0,
		borderWidth: 0,
		cropPositionTop: 0,
		cropPositionLeft: 0,
		cropWidth: 0,
		cropHeigth: 0,
		imageURL: "",
		showHighlight: false,
	});

	const notify = () =>
		toast.success("更新に成功", {
			position: "top-right",
			autoClose: 2000,
			hideProgressBar: false,
			pauseOnHover: false,
			draggable: true,
			progress: undefined,
			theme: "light",
		});

	const getCameraImage = async (id) => {
		setLoading(true);
		try {
			const response = await cameraService.getCameraImage(id);
			if (response !== null && response !== undefined) {
				setCamerasImage(response[0].replace(/\s/g, "+"));
			}
		} catch (err) {
			console.log("err", err);
			setCamerasImage(cameraImage);
		} finally {
			setLoading(false);
		}
	};

	const getCameraIp = async (id) => {
		try {
			const response = await cameraService.getCameraIp(id);
			if (response !== null && response !== undefined) {
				setCamerasIp(response?.ip);
			}
		} catch (err) {
			console.log("err", err);
		}
	};

	const drawRectangle = (target_rectangle: string) => {
		const listPoint = target_rectangle?.split(",");
		const startX = +listPoint[0] * 4.167;
		const startY = +listPoint[1] * 4.167;
		const endX = +listPoint[2] * 4.167;
		const endY = +listPoint[3] * 4.167;
		let cropPositionTop = startY;
		let cropPositionLeft = startX;
		let cropWidth = 0;
		let cropHeight = 0;

		cropWidth = endX - startX;
		cropHeight = endY - startY;
		cropPositionTop = endY;
		cropPositionLeft = endX;

		const newBorderWidth = `${startY}px ${value?.windowWidth - endX}px ${
			value?.windowHeight - endY
		}px ${startX}px`;
		setValue((prevState) => ({
			...prevState,
			borderWidth: newBorderWidth,
			showHighlight: true,
			cropWidth,
			cropHeight,
			cropPositionTop: cropPositionTop,
			cropPositionLeft: cropPositionLeft,
			endX: endX,
			endY: endY,
		}));
	};
	// const getCameraConfig = async (id) => {
	// 	setLoading(true);
	// 	try {
	// 		const response = await cameraService.getCameraDetail(id);
	// 		if (response !== null && response !== undefined) {
	// 			setDirectionTypeIn(response?.in_out_direction_type);
	// 			setDirectionTypeOut(response?.out_in_direction_type);
	// 		}

	// 		if (response?.target_rectangle) {
	// 			setBorderConfig(response?.target_rectangle);
	// 			setTimeout(() => {
	// 				drawRectangle(response?.target_rectangle);
	// 			}, 300);
	// 		}
	// 	} catch (err) {
	// 		console.log("err", err);
	// 	} finally {
	// 		setLoading(false);
	// 	}
	// };

	const handleUpdateConfig = async () => {
		setLoading(true);
		const body = {
			target_rectangle: borderConfig,
			in_out_direction_type: directionTypeIn,
			out_in_direction_type: directionTypeOut,
		};
		try {
			const response = await cameraService.updateCameraConfig(state?.id, body);
			if (response !== null && response !== undefined) {
				setErr(null);
				try {
					const response = await cameraService.requestCameraConfig(state?.id);
					if (response) notify();
				} catch (err) {
					console.log("err", err);
					err?.response?.data?.detail
						? setErr(err?.response?.data?.detail?.toString())
						: setErr(err?.response?.data?.detail[0]?.msg?.toString());
				}
			}
		} catch (err) {
			console.log("err", err);
			setErr(err?.response?.data?.detail?.toString());
		} finally {
			setLoading(false);
			close();
		}
	};

	useEffect(() => {
		if (state?.id) {
			getCameraImage(state?.id);
			getCameraIp(state?.id);
			setTimeout(() => {
				setDirectionTypeIn(state?.in_out_direction_type);
				setDirectionTypeOut(state?.out_in_direction_type);

				if (state?.target_rectangle) {
					setBorderConfig(state?.target_rectangle);
					setTimeout(() => {
						drawRectangle(state?.target_rectangle);
					}, 500);
				}
			}, 1500);
		}
	}, [value.windowWidth]);

	useEffect(() => {
		setTimeout(() => {
			!value.showHighlight &&
				setValue((prevState) => ({
					...prevState,
					borderWidth: 0,
				}));
		}, 200);
	}, [value.showHighlight]);

	useEffect(() => {
		const handleWindowResize = () => {
			const windowWidth = ref?.current?.clientWidth;
			const windowHeight = ref?.current?.clientHeight;

			setValue((prevState) => ({
				...prevState,
				windowWidth,
				windowHeight,
			}));
		};

		setTimeout(() => {
			window.addEventListener("resize", handleWindowResize);
			handleWindowResize();
			return () => window.removeEventListener("resize", handleWindowResize);
		}, 200);
	}, [camerasImage]);

	const handleScroll = () => {
		const distanceToTop = ref.current.getBoundingClientRect().top;
		const distanceToLeft = ref.current.getBoundingClientRect().left;
		setTopDistant(distanceToTop);
		setLeftDistant(distanceToLeft);
	};

	const options = ["カメラ005", "two", "three"];

	const handleMouseMove = (e: any) => {
		const {
			isMouseDown,
			windowWidth,
			windowHeight,
			startX,
			startY,
			borderWidth,
		} = value;
		let cropPositionTop = startY;
		let cropPositionLeft = startX;
		const endX = e.clientX - leftDistant;
		const endY = e.clientY - topDistant;
		const isStartTop = endY >= startY;
		const isStartBottom = endY <= startY;
		const isStartLeft = endX >= startX;
		const isStartRight = endX <= startX;
		const isStartTopLeft = isStartTop && isStartLeft;
		const isStartTopRight = isStartTop && isStartRight;
		const isStartBottomLeft = isStartBottom && isStartLeft;
		const isStartBottomRight = isStartBottom && isStartRight;
		let newBorderWidth = borderWidth;
		let cropWidth = 0;
		let cropHeigth = 0;

		if (isMouseDown) {
			if (isStartTopLeft) {
				newBorderWidth = `${startY}px ${windowWidth - endX}px ${
					windowHeight - endY
				}px ${startX}px`;
				cropWidth = endX - startX;
				cropHeigth = endY - startY;
			}

			if (isStartTopRight) {
				newBorderWidth = `${startY}px ${windowWidth - startX}px ${
					windowHeight - endY
				}px ${endX}px`;
				cropWidth = startX - endX;
				cropHeigth = endY - startY;
				cropPositionLeft = endX;
			}

			if (isStartBottomLeft) {
				newBorderWidth = `${endY}px ${windowWidth - endX}px ${
					windowHeight - startY
				}px ${startX}px`;
				cropWidth = endX - startX;
				cropHeigth = startY - endY;
				cropPositionTop = endY;
			}

			if (isStartBottomRight) {
				newBorderWidth = `${endY}px ${windowWidth - startX}px ${
					windowHeight - startY
				}px ${endX}px`;
				cropWidth = startX - endX;
				cropHeigth = startY - endY;
				cropPositionLeft = endX;
				cropPositionTop = endY;
			}
		}

		cropWidth *= window.devicePixelRatio;
		cropHeigth *= window.devicePixelRatio;

		setValue((prevState) => ({
			...prevState,
			borderWidth: newBorderWidth,
			showHighlight: true,
			cropWidth,
			cropHeigth,
			cropPositionTop: cropPositionTop,
			cropPositionLeft: cropPositionLeft,
			endX: endX,
			endY: endY,
		}));
	};

	const onImageError = (e) => {
		e.target.src = cameraImage;
	};

	const handleMouseDown = (e: any) => {
		const startX = e.clientX - leftDistant;
		const startY = e.clientY - topDistant;

		setValue((prevState) => ({
			...prevState,
			startX,
			startY,
			cropPositionTop: startY,
			cropPositionLeft: startX,
			isMouseDown: true,
		}));
	};

	const handleMouseUp = (e: any) => {
		setValue((prevState) => ({
			...prevState,
			on: false,
			isMouseDown: false,
		}));
		if (value.startX === value.endX) {
			console.log(value?.borderWidth);
		} else {
			if (value.startX < value.endX && value.startY < value.endY) {
				setBorderConfig(
					`${Math.floor(value.startX / 4.167)},${Math.floor(
						value.startY / 4.167,
					)},${Math.floor(value.endX / 4.167)},${Math.floor(
						value.endY / 4.167,
					)}`,
				);
			} else if (value.startX < value.endX && value.startY > value.endY) {
				setBorderConfig(
					`${Math.floor(value.startX / 4.167)},${Math.floor(
						value.endY / 4.167,
					)},${Math.floor(value.endX / 4.167)},${Math.floor(
						value.startY / 4.167,
					)}`,
				);
			} else if (value.startX > value.endX && value.startY < value.endY) {
				setBorderConfig(
					`${Math.floor(value.endX / 4.167)},${Math.floor(
						value.startY / 4.167,
					)},${Math.floor(value.startX / 4.167)},${Math.floor(
						value.endY / 4.167,
					)}`,
				);
			} else if (value.startX > value.endX && value.startY > value.endY) {
				setBorderConfig(
					`${Math.floor(value.endX / 4.167)},${Math.floor(
						value.endY / 4.167,
					)},${Math.floor(value.startX / 4.167)},${Math.floor(
						value.startY / 4.167,
					)}`,
				);
			}
		}
	};

	return (
		<div className='camera-detail-container'>
			<div
				className='camera-container d-flex flex-column'
				onScroll={handleScroll}
			>
				<div className='parkings-title'>
					{state?.parking_name || `parking ?`}ー{state?.name}　
				</div>
				{/* <div className='cameras-search d-flex flex-row'>
					<div className='search-dropdown flex-column d-flex'>
						<div className='search-dropdown-title'>カメラ</div>
						<Dropdown
							className='dropdown-search'
							controlClassName='dropdown-control-search'
							options={options}
							placeholder='すべて'
							disabled
						/>
					</div>
				</div> */}
				<div className='cameras-search d-flex flex-row f-16px al-st'>
					<label className='cam-ip-label'>カメラIP: </label>
					{camerasIp ? (
						<a
							href={`http://${camerasIp}:5048/`}
							target='_blank'
							rel='noopener noreferrer'
							style={{ marginBottom: 3 }}
						>
							{camerasIp}
						</a>
					) : (
						<div>--.--.--.--</div>
					)}
				</div>
				<div className='camera-control d-flex flex-row'>
					<div
						className='camera-info'
						ref={ref}
						onMouseMove={(e) => handleMouseMove(e)}
						onMouseDown={(e) => handleMouseDown(e)}
						onMouseUp={(e) => handleMouseUp(e)}
					>
						<img
							height={"100%"}
							src={`data:image/jpg;base64,${camerasImage}`}
							onError={onImageError}
						></img>
						<div
							className={`${value.showHighlight && "overlay highlighting"}`}
							style={{ borderWidth: `${value.borderWidth}` }}
						/>
					</div>

					<div className='camera-control-group d-flex flex-column'>
						<div
							className='top-button d-flex flex-column al-center j-center'
							onClick={open}
						>
							識別領域設定
						</div>

						<div
							className='group-button-cf d-flex flex-column al-center j-center'
							style={{ width: "240px", marginLeft: "30px" }}
						>
							{borderConfig ? (
								<div className='button-title'>{borderConfig}</div>
							) : (
								<div className='button-title'> </div>
							)}
							<div className='group-button-dr-cf d-flex flex-row al-center j-center'>
								<div className='group-button-dr-cf d-flex flex-column al-center j-center'>
									<div className='button-title'>入口方向</div>
									<Direction id={directionTypeIn}></Direction>
								</div>
								<div className='group-button-dr-cf d-flex flex-column al-center j-center'>
									<div className='button-title'>出口方向</div>
									<Direction id={directionTypeOut}></Direction>
								</div>
							</div>
						</div>
						<SelectDirection
							setDirectionTypeIn={setDirectionTypeIn}
							setDirectionTypeOut={setDirectionTypeOut}
						/>
					</div>
				</div>
				<span className='err-config'>{err}</span>
				<Modal>
					<div className='modal-cf d-flex flex-column'>
						<div className='modal-cf-title d-flex flex-row'>
							同意して設定を編集する
						</div>

						<div className='modal-cf-buttons d-flex flex-row'>
							<button
								className='modal-cf-button1 d-flex flex-row'
								onClick={close}
							>
								戻る
							</button>
							<button
								className='modal-cf-button2 d-flex flex-row'
								onClick={handleUpdateConfig}
							>
								登録
							</button>
						</div>
					</div>
				</Modal>
				<div
					className='bottom-button d-flex flex-column al-center j-center'
					onClick={() => navigate("/cameras")}
				>
					もどる
				</div>
				<ToastContainer
					position='top-right'
					autoClose={2000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme='colored'
				/>
			</div>
		</div>
	);
};
export default CameraDetail;
